html {
  height: 100%;
  background: linear-gradient(180deg, #ffffff 0%, #f9f9f9 100%);
}

body {
  margin: 0;
  font-family: Quicksand;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(180deg, #ffffff 0%, #f9f9f9 100%);
}

code {
  font-family: source-code-pro, 'Quicksand', Monaco, Consolas, 'Courier New',
    monospace;
}
